import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Marquee from 'react-fast-marquee'
import { GoArrowUpRight } from "react-icons/go";
import edu from '../../image/edu.avif'
import gld from '../../image/goldsmith.avif'
import otk from '../../image/otaku.png'
import kal from '../../image/kales.png'
import bol from '../../image/blog.png'
import Portimg from '../../image/Port.png'
import trash from "../../image/trash.png"
import man from "../../image/manag.png"
import devv from "../../image/deevvas.png"
import gum from "../../image/gum.png"
import zon from "../../image/zon.png"
import eve from "../../image/evie.png"
import verse from "../../image/verse.png"
import kisan from "../../image/kisan.png"
import per from "../../image/per.png"

function Port() {
    const potdata = [
        {  
            img: trash,
            title: "Full stack",
            detail: "trashDump {After login done}",
            a: "https://trash-dump.vercel.app/"

        },{ 
             img: Portimg,
            title: "React (GSAP)",
            detail: "shilwant portfolio",
            a: "https://shilwant-port.vercel.app/"

        },
        {
            img: kisan,
            title: "React (GSAP)",
            detail: "kisanMel",
            a: "https://www.kisanmel.com/"
        },
        {
            img: verse,
            title: "React (GSAP + Framer motion )",
            detail: "JuVrse",
            a: "https://juverse-site.vercel.app/"
        },
        {
            img: gum,
            title: "React (GSAP )",
            detail: "Gumshoes {in progress..}",
            a: "https://gumshoe-zeta.vercel.app/"
        },
        {
            img: zon,
            title: "Full stack ",
            detail: "zoneis",
            a: "https://zoneis.vercel.app/"
        },
        {
            img: per,
            title: "Full stack",
            detail: "percept Ai",
            a: "https://percept-ai.vercel.app/"
        },
        {
            img: eve,
            title: "React",
            detail: "Evie portfolio",
            a: "https://eviee.vercel.app/",
        },
        {
            img: man,
            title: "React (crud/login)",
            detail: "management",
            a: "https://crudd-login.vercel.app/",
        },
        {
            img: otk,
            title: "React (rest api)",
            detail: "Otaku",
            a: "https://otakuu.vercel.app/",
        },
        {
            img: devv,
            title: "Html/css",
            detail: "deevvas",
            a: "https://devass-three.vercel.app/",
        },
    
    ];

    return (
        <div className="our-main">
            <Container>
                <div className="upper-pot">
                    <p>03 // LET ME SHOW YOU</p>
                    <p className='right'>(( ALL PROJECTS ))</p>
                </div>
            </Container>
            <div className="down-mar">
                <Marquee>
                    <h1>Projects</h1>
                    <h1 className='vis'>Projects</h1>
                    <h1>Projects</h1>
                    <h1 className='vis'>Projects</h1>
                    <h1>Projects</h1>
                    <h1 className='vis'>Projects</h1>
                </Marquee>
            </div>
            <div className="port-detail">
                <Container>
                    <Row>
                        {potdata.map((elem, index) => (
                            <Col lg={6} key={index}>
                                <div className='card-pot'>
                                    <a href={elem.a} target='_blank' rel='noopener noreferrer'>
                                        <div className="box-pot">
                                            <div className="img-boc">
                                                <img src={elem.img} alt={elem.detail} />
                                            </div>
                                            <h6>{index + 1} &nbsp; // &nbsp; {elem.title}</h6>
                                            <div className="h1-down">
                                                <h1>{elem.detail}</h1>
                                                <div className="icon-box-pot">
                                                    <GoArrowUpRight />
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Port;